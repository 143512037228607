import { includes } from 'xe-utils'
<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="菜单名称:">
        <el-input v-model.trim="searchForm.permName" placeholder="菜单名称" clearable></el-input>
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      ref="xTable"
      id="menu"
      row-id="id"
      :loading="loading"
      :data="tableData"
      :row-config="{keyField:'permId'}"
      :tree-config="{expandAll:false}">
      <template #buttons>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="editVisible=true">新 增</el-button>
        <el-button size="small" type="primary" icon="el-icon-sort" @click="sortVisible=true">同级排序</el-button>
      </template>
      <vxe-column width="42"></vxe-column>
      <vxe-column type="seq" title="序号" tree-node></vxe-column>
      <vxe-column field="fullName" title="菜单名称">
        <template #default="{row}">{{row.permName}}</template>
      </vxe-column>
      <vxe-column field="permTypeName" title="类型"></vxe-column>
      <vxe-column field="permPicURL0" title="图标">
        <template #default={row}>
          <img v-if="row.permPicURL0" class="w-20" :src="getMediaUrl(row.permPicURL0)">
          <span v-else>-</span>
        </template>
      </vxe-column>
      <vxe-column field="accePath" title="路由">
        <template #default={row}>
          <span>{{row.accePath||'-'}}</span>
        </template>
      </vxe-column>
      <vxe-column field="activityName" title="状态"></vxe-column>
      <vxe-column title="操作">
        <template #default={row}>
          <el-button type="primary" @click="goEditPerm(row)">编辑</el-button>
          <el-button v-if="![2, 12].includes(Number(row.permType))" type="primary" @click="addSubPerm(row)">新增下级</el-button>
          <el-button type="danger" @click="deletePerm(row.permId)">删除</el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <!-- 新建|编辑 -->
    <edit-menu :visible.sync="editVisible" :edit-data.sync="editData" :perm-list="tableData" @refresh="initList(true)" />

    <!-- 同级排序 -->
    <sort-menu :visible.sync="sortVisible" :perm-list="tableData" @refresh="initList" />
  </PageContainer>
</template>

<script>
import { listToTreeList } from '@/utils/tools'
import { getPermList, delPermList } from '@/apis/authManage'
import EditMenu from './EditMenu'
import SortMenu from './SortMenu'
import { permTypes } from '@/constants/authManage/menu'
export default {
  data () {
    return {
      permTypes,
      searchForm: {
        permName: ''
      },
      // 表格相关
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 10000,
        totalSize: 0
      },
      loading: false,
      editData: null,
      editVisible: false,
      sortVisible: false
    }
  },
  created () {
    this.initList()
  },
  methods: {

    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },
    initList (doExpand) {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      getPermList(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          const expandRows = this.$refs.xTable.getTreeExpandRecords()
          this.tablePage.totalSize = res.data.totalSize
          const permTypes = this.permTypes
          const permList = res.data.rows.map((item) => {
            const target = permTypes.find(subItem => subItem.id == item.permType) || { name: '-' }
            item.permTypeName = target.name
            item.activityName = item.activity == 1 ? '启用' : '禁用'
            return item
          }).sort((a, b) => a.levelOrder - b.levelOrder)

          let newExpandRows = []
          if (doExpand) {
            const permIds = expandRows.map(item => item.permId)
            newExpandRows = permList.filter(item => permIds.includes(item.permId))
          }
          this.tableData = listToTreeList(permList)
          doExpand && this.$refs.xTable.setTreeExpand(newExpandRows, true)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },
    goEditPerm (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.editVisible = true
    },

    addSubPerm (row) {
      const editData = JSON.parse(JSON.stringify(row))
      this.editData = {
        permPId: editData.permId
      }
      this.editVisible = true
    },

    deletePerm (id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = { ids: [id] }
        delPermList(params).then((res) => {
          if (res.code) {
            this.initList(true)
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }
  },
  components: {
    EditMenu,
    SortMenu
  }
}
</script>
