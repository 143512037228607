<template>
  <el-dialog
    v-bind="{title:'同级排序',width:'420px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" :model="updateForm">
      <el-form-item label="上级菜单">
        <div class="flex">
          <el-cascader
            class="flex-1"
            ref="cascader"
            v-model="updateForm.permPId"
            :props="{value:'permId',label:'permName',emitPath:false,checkStrictly:true}"
            :show-all-levels="false"
            :options="permListFormat"
            clearable
          />
        </div>
      </el-form-item>
      <!-- 利用element的class来使用主题色 -->
      <draggable v-model="selectedList" chosen-class="el-tag--plain" force-fallback="true" group="people" animation="300" @start="onStart" @end="onEnd">
        <transition-group>
          <div class="item" v-for="item in selectedList" :key="item.permId">{{item.permName}}</div>
        </transition-group>
      </draggable>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { permcsOrder } from '@/apis/authManage'
import draggable from 'vuedraggable'
import { mapActions } from 'vuex'
import XEUtils from 'xe-utils'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },

    permList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    },
    permListFormat () {
      return this.permList
    }
  },
  data () {
    return {
      loading: false,
      updateForm: {
        permPId: ''
      },
      selectedList: [],
      drag: false
    }
  },
  watch: {
    'updateForm.permPId': {
      immediate: true,
      handler (value) {
        const permList = JSON.parse(JSON.stringify(this.permList))
        if (value) {
          const target = XEUtils.findTree(permList, (item) => item.permId == value)
          if (target) {
            this.selectedList = target.item.children
          } else {
            this.selectedList = []
          }
        } else {
          this.selectedList = permList
        }
      }
    }
  },
  methods: {
    ...mapActions('account', ['refreshToken']),

    onStart () {
      this.drag = true
    },
    // 拖拽结束事件
    onEnd () {
      this.drag = false
    },
    submit () {
      const permDtos = this.selectedList.map((item, index) => {
        return {
          permId: item.permId,
          levelOrder: index + 1
        }
      })
      this.loading = true
      permcsOrder(permDtos).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.$message.success('操作成功！')
          this.$emit('refresh')
          this.visibleDialog = false
          // 刷新token和用户信息
          this.refreshToken()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlerOpen () {
      this.selectedList = JSON.parse(JSON.stringify(this.permList))
    },

    handlerClose () {
      this.$emit('update:editData', null)
      this.updateForm = { permPId: '' }
      this.$refs.form.resetFields()
    }
  },
  components: {
    draggable
  }
}
</script>

<style scoped lang="scss">
/*被拖拽对象的样式*/
.item {
  padding: 12px;
  border-radius: 4px;
  background-color: #fdfdfd;
  border: solid 1px #eee;
  margin-bottom: 5px;
  cursor: move;
}
.item:hover {
  background-color: #f1f1f1;
  cursor: move;
}
.chosen {
  border: solid 2px #3089dc !important;
}

::v-deep .el-dialog__body {
  max-height: calc(95vh - 100px);
  overflow-y: auto;
}
::v-deep .el-dialog {
  top: 50%;
  margin: 0 auto 0!important;
  transform: translateY(-50%);
}
</style>
