var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContainer',[_c('BaseSearchForm',{attrs:{"loading":_vm.loading},on:{"search":_vm.onSearch,"reset":_vm.onReset}},[_c('el-form-item',{attrs:{"label":"菜单名称:"}},[_c('el-input',{attrs:{"placeholder":"菜单名称","clearable":""},model:{value:(_vm.searchForm.permName),callback:function ($$v) {_vm.$set(_vm.searchForm, "permName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"searchForm.permName"}})],1)],1),_c('BaseTable',{ref:"xTable",attrs:{"id":"menu","row-id":"id","loading":_vm.loading,"data":_vm.tableData,"row-config":{keyField:'permId'},"tree-config":{expandAll:false}},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-plus"},on:{"click":function($event){_vm.editVisible=true}}},[_vm._v("新 增")]),_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-sort"},on:{"click":function($event){_vm.sortVisible=true}}},[_vm._v("同级排序")])]},proxy:true}])},[_c('vxe-column',{attrs:{"width":"42"}}),_c('vxe-column',{attrs:{"type":"seq","title":"序号","tree-node":""}}),_c('vxe-column',{attrs:{"field":"fullName","title":"菜单名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.permName))]}}])}),_c('vxe-column',{attrs:{"field":"permTypeName","title":"类型"}}),_c('vxe-column',{attrs:{"field":"permPicURL0","title":"图标"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.permPicURL0)?_c('img',{staticClass:"w-20",attrs:{"src":_vm.getMediaUrl(row.permPicURL0)}}):_c('span',[_vm._v("-")])]}}])}),_c('vxe-column',{attrs:{"field":"accePath","title":"路由"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.accePath||'-'))])]}}])}),_c('vxe-column',{attrs:{"field":"activityName","title":"状态"}}),_c('vxe-column',{attrs:{"title":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.goEditPerm(row)}}},[_vm._v("编辑")]),(![2, 12].includes(Number(row.permType)))?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addSubPerm(row)}}},[_vm._v("新增下级")]):_vm._e(),_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.deletePerm(row.permId)}}},[_vm._v("删除")])]}}])})],1),_c('edit-menu',{attrs:{"visible":_vm.editVisible,"edit-data":_vm.editData,"perm-list":_vm.tableData},on:{"update:visible":function($event){_vm.editVisible=$event},"update:editData":function($event){_vm.editData=$event},"update:edit-data":function($event){_vm.editData=$event},"refresh":function($event){return _vm.initList(true)}}}),_c('sort-menu',{attrs:{"visible":_vm.sortVisible,"perm-list":_vm.tableData},on:{"update:visible":function($event){_vm.sortVisible=$event},"refresh":_vm.initList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }