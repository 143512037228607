<template>
  <div class="upload-picture" v-loading="loading">
    <el-upload
      v-if="!src"
      ref="uploadRef"
      list-type="picture-card"
      style="width:0;height:0;overflow:hidden;"
      :action="url"
      :http-request="uploadPicture"
      :limit="limit">
    </el-upload>

    <div
      v-if="!hidePicker"
      @click="prepareUpload"
      class="upload-picker flex justify-center align-center">
      <i class="el-icon-plus fs-24"></i>
    </div>

    <div v-if="src" class="picture-thumb">
      <img class="picture-item" :src="getMediaUrl(src)" >
      <div class="picture-actions justify-center align-center">
        <i class="el-icon-delete" @click="$emit('remove')"></i>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/http'
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      url: http.baseUrl + '/basefun/fdfscs/uploadOne',
      loading: false
    }
  },
  computed: {
    hidePicker () {
      return this.limit == 1 && !!this.src
    }
  },
  methods: {
    // 上传图片
    uploadPicture (content) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', content.file)
      http.postForm(this.url, formData).then((res) => {
        this.$emit('uploaded', res.data)
        this.loading = false
      })
    },

    prepareUpload () {
      console.log(this.$refs.uploadRef.$el.lastElementChild.click())
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-picture {
  position: relative;
  width: 120px;
  height: 120px;

  .upload-picker {
    width: 120px;
    height: 120px;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    vertical-align: top;
    cursor: pointer;

    // &:hover {
    //   border-color: #999;
    // }
  }

  .picture-thumb {
    position: relative;
    width: 120px;
    height: 120px;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    overflow: hidden;

    .picture-item {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }

    &:hover {
      border-color: transparent;
      .picture-actions {
        display: flex;
      }
    }

    .picture-actions {
      position: absolute;
      display: none;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      color: #fff;
      font-size: 20px;
      background-color: rgba(0,0,0,.5);
    }

    .picture-actions > i {
      cursor: pointer;
    }
  }

  .el-icon-plus {
    color: #8c939d;
  }
}
</style>
