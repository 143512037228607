<template>
  <el-dialog
    v-bind="{title:editData&&editData.permId?'编辑菜单':'新增菜单', width:'420px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="130px" :model="updateForm" :rules="rules">
      <el-form-item label="菜单类型" prop="permType">
        <el-select class="w-200" v-model="updateForm.permType" clearable @change="updateForm.accePath=''">
          <el-option v-for="({name, id}) in permTypes" :key="id" :label="name" :value="id" />
        </el-select>
      </el-form-item>
      <el-form-item label="菜单父级" >
        <el-cascader
          ref="cascader"
          class="w-200"
          v-model="updateForm.permPId"
          :props="{value:'permId',label:'permName',emitPath:false,checkStrictly:true}"
          :show-all-levels="false"
          :options="permListFormat"
          placeholder="不选，即无父级菜单"
          clearable
        />
      </el-form-item>
      <el-form-item label="菜单名称" prop="permName">
        <el-input class="w-200" v-model.trim="updateForm.permName" clearable></el-input>
      </el-form-item>
      <el-form-item label="权限码" prop="permCode">
        <el-input class="w-200" v-model="updateForm.permCode" clearable></el-input>
        <i class="ml-10 el-icon-document-copy pointer" v-clipboard="updateForm.permCode"></i>
      </el-form-item>
      <!-- 类型为菜单时展示 -->
      <el-form-item v-if="updateForm.permType==1" label="菜单路由path" prop="accePath">
        <el-input class="w-200" v-model.trim="updateForm.accePath" clearable></el-input>
      </el-form-item>
      <el-form-item label="菜单顺序" prop="levelOrder" >
        <el-input class="w-200" v-model.number="updateForm.levelOrder" clearable></el-input>
      </el-form-item>
      <el-form-item label="菜单图标" prop="permPicURL0">
        <upload-pic
          :src="updateForm.permPicURL0"
          @uploaded="updateForm.permPicURL0=$event.nowRelatUrl"
          @remove="updateForm.permPicURL0=''"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import UploadPic from '@/components/UploadPic'
import { updatePerm } from '@/apis/authManage'
import { mapActions } from 'vuex'
import XEUtils from 'xe-utils'
import { permTypes } from '@/constants/authManage/menu'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    },
    permList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    },
    permListFormat () {
      return this.permList
    }
  },
  data () {
    return {
      permTypes,
      loading: false,
      updateForm: {
        permId: '',
        permPId: '',
        permType: '',
        permName: '',
        levelOrder: '',
        accePath: '',
        permCode: '',
        permLevel: '',
        permPicURL0: '' // 图标
      },
      rules: {
        permType: { required: true, message: '请选择菜单类型' },
        permPId: { required: true, message: '请选择菜单父级' },
        permName: { required: true, message: '请输入菜单名称' }
      }
    }
  },
  methods: {
    ...mapActions('account', ['refreshToken']),

    submit () {
      const updateForm = { ...this.updateForm }
      const checkedNodes = this.$refs.cascader.getCheckedNodes()
      let permLevel = 1
      if (checkedNodes.length > 0) {
        permLevel = checkedNodes[0].path.length + 1
      }
      updateForm.permLevel = permLevel
      // 判断权限码是否唯一
      const target = XEUtils.findTree(this.permList, (item) => {
        return item.permCode == updateForm.permCode && item.permId != updateForm.permId
      })
      if (target) return this.$message.error('权限码重复，请查看当前菜单下该菜单名是否唯一')
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          updatePerm(this.updateForm).then(async (res) => {
            this.loading = false
            if (res.code == 200) {
              if (!updateForm.permId) {
                await this.$confirm('新增成功，是否继续新增菜单？', '提示', {
                  confirmButtonText: '继续新增',
                  cancelButtonText: '返回列表',
                  type: 'warning'
                }).catch(() => {
                  this.$emit('refresh')
                  this.visibleDialog = false
                  // 刷新token和用户信息
                  this.refreshToken()
                  updateForm.permName = ''
                  updateForm.levelOrder = ''
                  updateForm.permCode = ''
                })
              } else {
                this.$message.success('编辑成功！')
                this.$emit('refresh')
                this.visibleDialog = false
                // 刷新token和用户信息
                this.refreshToken()
              }
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    handlerOpen () {
      if (this.editData) {
        Object.keys(this.updateForm).forEach((key) => {
          const value = this.editData[key]
          if (value) this.updateForm[key] = value
        })
      }
    },

    handlerClose () {
      this.$emit('update:editData', null)
      this.updateForm = {
        permId: '',
        permPId: '',
        permType: '',
        permName: '',
        levelOrder: '',
        accePath: '',
        permCode: '',
        permLevel: '',
        permPicURL0: ''
      }
      this.$refs.form.resetFields()
    },

    setPermCode () {
      const updateForm = this.updateForm
      const permPId = updateForm.permPId || ''
      const permName = updateForm.permName || ''
      if (permName) {
        let prefixArr = []
        // 生成前缀
        const target = XEUtils.findTree(this.permList, item => item.permId == permPId)
        if (target) prefixArr = target.nodes.map(item => item.permName)
        const permCode = ([...prefixArr, permName]).filter(item => Boolean(item)).join('_')
        updateForm.permCode = permCode
      } else {
        updateForm.permCode = ''
      }
      console.log(updateForm, 'updatePerm.permCode')
    }
  },
  components: {
    UploadPic
  }
}
</script>
