import http from '@/utils/http'

export function updatePerm (p) {
  return http.post('/mortconc/permcs/set', p)
}

export function getPermList (p) {
  return http.post('/mortconc/permcs/list', p)
}

export function delPermList (p) {
  return http.post('/mortconc/permcs/del', p)
}

export function permcsOrder (p) {
  return http.post('/mortconc/permcs/batSetOrder', p)
}
